import styled from 'styled-components'
import { typography, color, Media } from '@cgtalks/common/theme'
import { Flex } from '@cgtalks/common/components'
import BannerBg from '../images/guest/guest-bg.png'
import BannerBgMb from '../images/guest/guest-bg-mb.png'

export const BannerContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: ${`url(${BannerBg})`};
  background-size: 100%;
  background-repeat: no-repeat;
  min-width: 1440px;
  ${Media.lessThan(Media.small)} {
    min-width: unset;
    background-image: ${`url(${BannerBgMb})`};
  }
`

export const GuestForm = styled.div`
  width: 800px;
  height: 660px;
  background: #2e2f3a;
  margin: 100px auto;
  color: ${color.white};
  border-radius: 10px;
  box-sizing: border-box;
  padding-top: 25px;
  h1 {
    text-align: center;
  }
  ${Media.lessThan(Media.small)} {
    width: 90.66vw;
    padding: 10px 0 31px;
    height: auto;
    margin: 95px auto 35px;
  }
`
export const Form = styled.form`
  margin: auto;
  padding: 0 140px;
  text-align: right;
  button {
    width: 160px;
    display: flex;
    justify-content: center;
    margin: 30px auto;
    background: #00ffab;
    color: #21252a;
    &:disabled {
      cursor: not-allowed;
      filter: unset !important;
      background-color: #383844 !important;
      color: #21252a !important;
      font-size: 16px;
      box-shadow: none !important;
      border: none !important;
    }
  }
  ${Media.lessThan(Media.small)} {
    padding: 0 20px;
    text-align: left;
    button {
      width: 100%;
      height: 35px;
      margin: 27px 0 0;
      font-size: 15px;
    }
  }
`
export const InputStyle = `
  flex-grow: 1;
  color: white;
  border: 1px solid #515160;
  border-radius: 4px;
  width: 401px;
  background: #383844;
  font-size: 14px;
  box-sizing: border-box;
  ::placeholder { color: #7B7B8B; }
  :focus-visible { outline:none; }

  ${Media.lessThan(Media.small)} {
    width: 100%;
  }
`
export const InputRow = styled(Flex)`
  font-size: ${typography.textSmall};
  line-height: 60px;
  label {
    margin: 0 1em;
    font-weight: bold;
    color: #ffffff;
    width: 92px;
  }
  input {
    ${InputStyle};
    height: 37px;
    padding: 0 10px;
  }
  textarea {
    ${InputStyle};
    height: 100px;
    padding: 10px;
    max-height: 120px;
  }
  &.text-area {
    align-items: baseline;
  }
  ${Media.lessThan(Media.small)} {
    flex-direction: column;
    line-height: 40px;
    align-items: flex-start;
    label {
      margin: 0;
    }
    &.text-area {
      align-items: flex-start;
    }
  }
`

export const ShowDialog = styled.div`
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DialogContnt = styled.div`
  background-color: #2e2f3a;
  padding: 30px 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  button {
    border: none;
    text-align: center;
    width: 120px;
    height: 40px;
    background: #00ffab;
    border-radius: 20px;
  }
  p {
    font-size: 16px;
  }
  ${Media.lessThan(Media.small)} {
    padding: 20px 20px;
    button {
      width: 110px;
      height: 30px;
    }
    p {
      font-size: 14px;
    }
  }
`

export const ErrorText = styled.div`
  color: #f14c40;
  font-size: 14px;
  text-align: left;
  margin-left: 24%;
  margin-top: ${(p) => (p.mt ? p.mt : '-8px')};
  margin-bottom: 5px;
  ${Media.lessThan(Media.small)} {
    margin-left: 0;
    margin-top: 2px;
    color: #f14c40 !important;
  }
`
