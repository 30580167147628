import React, { useState, useEffect } from 'react'
import { API, Axios } from '@cgtalks/common/utils'
import { Seo, Button } from '@cgtalks/common/components'
import Layout from '../molecules/layout'
import AboutCg from '../molecules/guests/'
import {
  BannerContainer,
  Form,
  InputRow,
  GuestForm,
  ShowDialog,
  DialogContnt,
  ErrorText,
} from './guest-recommendation.atom'
import { isTest } from '@cgtalks/common/service'

function BecomeSpeaker(props) {
  const [name, setName] = useState('') // 姓名
  const [phone, setPhone] = useState('') // 联系方式
  const [wechat, setWechat] = useState('') // 微信号
  const [cgSocialMedia, setCgSocialMedia] = useState('') // 社媒链接
  const [introduction, setIntroduction] = useState('') // 个人简介
  const [submitted, setSubmitted] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const instance = isTest() ? new Axios(API.auth.testHost) : new Axios(API.auth.host)

  const [isNameValid, setIsNameValid] = useState(false)
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [isSocialMediaValid, setIsSocialMediaValid] = useState(false)
  const [isWechatValid, setIsWechatValid] = useState(false)
  const [isIntroductionValid, setIsIntroductionValid] = useState(false)

  const [nameValidText, setNameValidText] = useState('')
  const [phoneValidText, setPhoneValidText] = useState('')
  const [socialMediaValidText, setSocialMediaValidText] = useState('')
  const [wechatValidText, setWechatValidText] = useState('')
  const [IntroductionValidText, setIntroductionValidText] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    // 表单验证
    if (
      !name ||
      !phone ||
      !cgSocialMedia ||
      isNameValid ||
      isPhoneValid ||
      isSocialMediaValid ||
      isWechatValid ||
      isIntroductionValid
    ) {
      alert('请填写完整信息')
      return
    }
    if (!/^1[3456789]\d{9}$/.test(phone)) {
      alert('手机号码格式不正确')
      return
    }

    // 构建请求参数
    const data = {
      name,
      phone,
      wechat,
      socialMedia: cgSocialMedia,
      introduction,
      type: 1,
      createTime: Date.now(),
    }
    instance
      .post(API.event.guest, {
        data,
        event: 'cgForm',
      })
      .then((res) => {
        if (res.code === 200) {
          setSubmitted(true)
        }
      })
  }

  const confirm = () => {
    setSubmitted(false)
    setName('')
    setPhone('')
    setWechat('')
    setCgSocialMedia('')
    setIntroduction('')
  }

  const handleNameChange = (e) => {
    const value = e.target.value
    setName(value)
    if (value.trim() === '') {
      setIsNameValid(true)
      setNameValidText('必填')
    } else if (value.trim().length > 200) {
      setIsNameValid(true)
      setNameValidText('输入不超过200字符')
    } else {
      setIsNameValid(false)
      setNameValidText('')
    }
  }

  const handlePhoneChange = (e) => {
    const value = e.target.value
    setPhone(value)
    if (value.trim() === '') {
      setIsPhoneValid(true)
      setPhoneValidText('必填')
    } else if (!/^1[3456789]\d{9}$/.test(value)) {
      setIsPhoneValid(true)
      setPhoneValidText('手机号码格式不正确')
    } else {
      setIsPhoneValid(false)
      setPhoneValidText('')
    }
  }

  const handleWechatChange = (e) => {
    const value = e.target.value
    setWechat(value)
    if (value.trim().length > 200) {
      setIsWechatValid(true)
      setWechatValidText('输入不超过200字符')
    } else {
      setIsWechatValid(false)
      setWechatValidText('')
    }
  }

  const handleSocialMediaChange = (e) => {
    const value = e.target.value
    setCgSocialMedia(value)
    if (value.trim() === '') {
      setIsSocialMediaValid(true)
      setSocialMediaValidText('必填')
    } else if (value.trim().length > 500) {
      setIsSocialMediaValid(true)
      setSocialMediaValidText('输入不超过500字符')
    } else {
      setIsSocialMediaValid(false)
      setSocialMediaValidText('')
    }
  }

  const handleIntroductionChange = (e) => {
    const value = e.target.value
    setIntroduction(value)
    if (value.trim().length > 500) {
      setIsIntroductionValid(true)
      setIntroductionValidText('输入不超过500字符')
    } else {
      setIsIntroductionValid(false)
      setIntroductionValidText('')
    }
  }

  useEffect(() => {
    if (
      isNameValid ||
      isPhoneValid ||
      isSocialMediaValid ||
      isWechatValid ||
      isIntroductionValid ||
      !name ||
      !phone ||
      !cgSocialMedia
    ) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }
  }, [
    isNameValid,
    isPhoneValid,
    isSocialMediaValid,
    isWechatValid,
    isIntroductionValid,
    name,
    phone,
    cgSocialMedia,
  ])

  const { location } = props
  return (
    <Layout location={location}>
      <Seo
        title='成为嘉宾-泛CG聚未来-Renderbus瑞云渲染'
        keywords='泛CG, CG技术线上分享会,cg分享网,CG分享平台'
        description='泛CG聚未来，分线上直播、线下交流会，是一个属于CG人的自由分享、交流平台，成为分享嘉宾可获得行业曝光、与技术大佬交流机会以及专属礼品，诚邀您的加入。'
        sharePostSlug='become-a-speaker.html'
      />
      <BannerContainer />
      <GuestForm>
        <h1>成为嘉宾</h1>
        <Form onSubmit={handleSubmit}>
          <InputRow>
            <label htmlFor='name'>*姓名</label>
            <input
              id='name'
              type='text'
              value={name}
              onBlur={handleNameChange}
              onChange={handleNameChange}
              placeholder='请输入你的姓名'
            />
          </InputRow>
          {isNameValid && <ErrorText>{nameValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='phone'>*电话</label>
            <input
              id='phone'
              type='tel'
              value={phone}
              pattern='[0-9]{11}'
              onBlur={handlePhoneChange}
              onChange={handlePhoneChange}
              placeholder='请输入你的电话'
            />
          </InputRow>
          {isPhoneValid && <ErrorText>{phoneValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='wechat'>微信号</label>
            <input
              id='wechat'
              type='tel'
              value={wechat}
              onBlur={handleWechatChange}
              onChange={handleWechatChange}
              placeholder='请输入你的微信号'
            />
          </InputRow>
          {isWechatValid && <ErrorText>{wechatValidText}</ErrorText>}
          <InputRow>
            <label htmlFor='cgSocialMedia'>*社媒链接</label>
            <input
              id='cgSocialMedia'
              type='text'
              value={cgSocialMedia}
              onBlur={handleSocialMediaChange}
              onChange={handleSocialMediaChange}
              placeholder='请输入他/她的网站/社媒链接'
            />
          </InputRow>
          {isSocialMediaValid && <ErrorText>{socialMediaValidText}</ErrorText>}
          <InputRow className='text-area'>
            <label htmlFor='introduction'>个人简介</label>
            <textarea
              id='introduction'
              value={introduction}
              onBlur={handleIntroductionChange}
              onChange={handleIntroductionChange}
              placeholder='请简单说下您推荐他/她的理由，比如擅长领域，你是从哪里认识他/她的'
            />
          </InputRow>
          {isIntroductionValid && <ErrorText mt={'3px'}>{IntroductionValidText}</ErrorText>}
          <Button disabled={disableSubmit}>提交</Button>
        </Form>
        {submitted && (
          <ShowDialog>
            <DialogContnt>
              <p>感谢您的提交，我们会尽快联系您的！</p>
              <button onClick={confirm}>好的</button>
            </DialogContnt>
          </ShowDialog>
        )}
      </GuestForm>
      <AboutCg />
    </Layout>
  )
}

export default BecomeSpeaker
